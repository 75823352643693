import React from 'react'
import ServiceSidebarh from './ServiceSidebarh'
import details1h from '../../assets/images/services/services-details1h.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContenth = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1h} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">BEX/BW </span>
                            <h3>About this Service</h3>
                            <p>Reporting, analysis, and interpretation of business data are crucial to preserve and enhance the competitive edge of companies by optimizing processes and enabling them to react quickly and in line with market needs</p>
                            <p>Our SAP BW Service provides you with a high-performance infrastructure that helps you evaluate and interpret your data. This will help you make well-founded decisions and identify target-orientated activities based on the analyzed data. In SAP BW, you can integrate, transform, and consolidate relevant business information from productive SAP applications and external data sources. </p>
                            <p>Our SAP Business Explorer (SAP BEx) service provides you flexible reporting and analysis tools for strategic analyses and decision-making support within your business. It includes tools like query, reporting, and analysis functions. With access authorization, you can evaluate historical or current data at various levels of detail and from different perspectives; both on the Web, in the portal, and in Microsoft Excel. You can also use the SAP Business Explorer tools to create planning applications and for planning and data entry in BW Integrated Planning</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Multi-dimensional data analysis</li>
                                            <li>Integrated planning</li>
                                            <li>Information Broadcasting</li>
                                            <li>Pre-calculated historical data</li>
                                            <li>OLAP reporting</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>We aim to become your strategic BW Service provider. Business Intelligence is our passion and we believe, as does any ERP, those analytics embedded in every aspect of business, is the way of the future of business software. We can support both the analytical aspects of our customer's projects as well as the pure SAP ERP configuration aspects of their projects, which are 'revealed by the analysis. With our deep hands-on experience in real SAP BEx and BW services, our global network of SAP Certified Resources, we know what you need. We believe this helps you ensure that your project gets done on time, on budget, and in scope.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>

                                <div className="col-lg-5 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>BW</span></li>
                                <li><span>BEx query designer</span></li>
                                <li><span>Excel</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarh />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContenth