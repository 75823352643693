import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContenth from '../components/ServiceDetailsh/ServiceDetailsContenth'
import RelatedServicesh from '../components/ServiceDetailsh/RelatedServicesh'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="BEX/BW "
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContenth />
            <RelatedServicesh />
            <Footer />
        </Layout>
    );
}

export default Details